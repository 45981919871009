import React from 'react'
import styled from 'styled-components'
import { Grid, Item, Row } from '../components/flexGrid'

const MailLink = styled.a`
  font-style: italic;
`

const ContactLayout = () => {
  return (
    <Grid>
      <Row>
        <Item>
          <span>Dimitri Howald</span>
          <span>3005 Bern</span>
        </Item>
      </Row>

      <Row>
        <Item>
          <MailLink href="mailto:dimitri.howald@gmail.com">mail</MailLink>
        </Item>
      </Row>
    </Grid>
  )
}

export default ContactLayout
